import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation, Trans } from 'react-i18next';

function ResearcherPermissionsTableHead(props) {
  const { headers, onClick } = props;

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell onClick={() => {onClick(headCell.id)}} sx={{ width: headCell.width, textAlign: headCell.align, cursor: headCell.id !== 'key' ? 'pointer' : 'auto' }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ResearcherPermissionsTableHead.propTypes = {
  headers: PropTypes.array.isRequired
};

function ResearcherPermissionsTableToolbar(props) {
  const { hasChanges, saveIcon, deleteIcon } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(hasChanges && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {hasChanges ? (
        <>
          <Tooltip title={t("Save changes")}>
            <IconButton color="success" size="large" onClick={props.onSave}>
              {saveIcon}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Discard changes")}>
          <IconButton color="error" size="large" onClick={props.onDiscard}>
            {deleteIcon}
          </IconButton>
        </Tooltip>
        </>
      ) : (
        null
      )}
    </Toolbar>
  );
}

ResearcherPermissionsTableToolbar.propTypes = {
  saveIcon: PropTypes.object.isRequired,
  deleteIcon: PropTypes.object.isRequired,
};

export default function ResearcherPermissionsTable({headers, permissions, onAction, saveIcon, deleteIcon}) {
  const [hasChanges, setHasChanges] = React.useState(false);
  const [rows, setRows] = React.useState(permissions);
  const [toggle, setToggle] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setRows(permissions);
  }, [permissions])

  const handleChange = (idx) => {
    rows[idx].value = !rows[idx].value;
    setRows([...rows]);
    setHasChanges(true);
  };

  const handleSelectAll = () => {
    rows.forEach((row) => {
        row.value = !toggle;
    });

    setHasChanges(true);
    setToggle(!toggle);

    return;
  }

  return (
    <Box sx={{ width: '90%' }}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <ResearcherPermissionsTableHead
              headers={headers}
              hasChanges={hasChanges}
              rowCount={rows.length}
              onClick={handleSelectAll}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={t(row.key)}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="8px"
                      >
                        <Trans>{row.key}</Trans>
                      </TableCell>
                      <TableCell padding="checkbox" sx={{textAlign: 'center'}}>
                        <Checkbox
                          color="primary"
                          checked={row.value}
                          onChange={() => handleChange(index)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <ResearcherPermissionsTableToolbar 
            hasChanges={hasChanges} 
            saveIcon={saveIcon}
            deleteIcon={deleteIcon}
            onSave={() => {
              onAction(rows);
              setHasChanges(false);
            }}
            onDiscard={() => {
              setRows([...gamesInfo]);
              setHasChanges(false);
          }}
        />
    </Box>
  );
}
