import * as React from "react"
import { db } from '../../../firebase-info';
import { collection, getDocs, updateDoc} from "firebase/firestore";
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { 
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography
} from "@mui/material"
import BeGeneralPage from "../../components/be-general-page";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ResearcherPermissionsTable from "../../components/ResearcherPermissionsTable";

// markup
const manageResearcherPermissionsPage = () => {
	const { t } = useTranslation();
  const [recentResearchers, setRecentResearchers] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [userSelected, setUserSelected] = React.useState(null);

    const headers = [
        {
          id: 'key',
          align: 'left',
          width: '60%',
          label: t('Permission'),
        },
        {
          id: 'value',
          align: 'center',
          width: '20%',
          label: t('Activated'),
        },
      ];

	async function getDocumentData() {
    const researchers = [];
    const q =  await getDocs(collection(db, "users"));
    q.forEach((doc) => {
      researchers.push({
          id: doc.id,
          ref: doc.ref,
          ...doc.data(),
      });
    });

    setRecentResearchers(researchers);
    if (researchers.length) {
      setUserSelected(researchers[0]);
    }
    setLoading(false);
	};

  function buildGeneralPermissionsForResearcher(researcher){
    const permissions = [];

    if(researcher){
      if(!researcher.generalPermissions) {
        researcher.generalPermissions = { canDownload: false };
      }
      const generalPermissions = researcher.generalPermissions;
      const keys = Object.keys(generalPermissions);

      keys.forEach((g) => {
        permissions.push({
          key: g,
          value: generalPermissions[g],
        })
      });
    }

    return permissions;
  }

  function buildChangesToPermissions(changes = []) {
    if(!changes) return permissions;

    const permissions = Object.fromEntries(changes.map(item => [item.key, item.value]));

    userSelected.generalPermissions = { ...userSelected.generalPermissions, ...permissions };

    return permissions;
  };

  async function savePermissions(changes) {
    const generalPermissions = buildChangesToPermissions(changes);

    await updateDoc(userSelected.ref, {
        generalPermissions
    });
  }

  React.useEffect(() => {
    if (!recentResearchers.length){
      getDocumentData();
    }
	}, []);


  return (
    <BeGeneralPage title={t("Manage Researcher Permissions")}>
        {loading ?
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        :
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "64px",
          }}>
            <Grid container sx={{width: '80%', border: '1px solid rgba(0, 0, 0, 0.6)', borderRadius: '8px', padding: '8px'}}>
              <Grid item xs={3}>
                <Box sx={{maxHeight: '90vh', "overflow-y": 'auto'}}>
                  <Grid container direction={"column"}>
                    {
                      recentResearchers.map((r) => (
                        <Grid item xs={12} onClick={() => { setUserSelected(r) }}>
                          <Paper elevation={0} square={true} sx={{"&:hover": { backgroundColor: r===userSelected ? null : '#D1EAF2' }, backgroundColor: r===userSelected ? '#98D1E3' : null }}>
                          <Tooltip enterNextDelay={500} placement="left" arrow title=
                            {
                              <div>
                                <strong>Email:</strong> {r.email}
                                <br/>
                                <strong>Org:</strong> {r.organization}
                                <br/>
                                <strong>Id:</strong> {r.id}
                              </div>
                            }>
                              <Grid container p={1} sx={{cursor: 'pointer'}}>
                                <Grid item xs={11}>
                                  <Typography>{`${r.name} ${r.surname}`}</Typography>
                                </Grid>
                                  <Grid item xs={1} alignSelf="center">
                                    <ArrowForwardIosIcon sx={{ color: r===userSelected ? '#000' : 'transparent'  }}/>
                                  </Grid>
                              </Grid>
                            </Tooltip>
                          </Paper>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={9}>
                  {
                    userSelected ?
                      <ResearcherPermissionsTable 
                        headers={headers}
                        permissions={buildGeneralPermissionsForResearcher(userSelected)} 
                        onAction={savePermissions}
                        saveIcon={<SaveIcon fontSize="large"/>}
                        deleteIcon={<DeleteIcon fontSize="large"/>}
                      />
                    :
                      null
                  }
              </Grid>
            </Grid>
          </Box>
        }
    </BeGeneralPage>
  )
}

export default manageResearcherPermissionsPage

export const graphQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "administration_validate", "enhanced_table"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;